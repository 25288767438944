import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgHero from "../images/gfx/hero-guarantee-large.jpg"
import imgMobileHero from "../images/gfx/hero-guarantee-mobile.jpg"
import imgFiveStar from "../images/gfx/guarantee-5-stars.png"
import imgWeGuarantee from "../images/gfx/we-guarantee.png"


import imgTick from "../images/gfx/guarantee-tick.png"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./money-back-guarantee.css";
import Nav from "../components/nav"
import Footer from "../components/footer"

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'sell'
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="100-day money back guarantee | bus ticketing platform for school and work" description="Simplify your home to school services with ShuttleID. Payments, ticket distribution and scanning using a simple but cost-effective approach." />
        <Nav activeTab="packages" />
        <div>
          <div>

            <div className='guarantee'>
              <picture>
                <source media="(max-width: 599px)" srcSet={`${imgMobileHero}`}/>
                <source media="(min-width: 600px)" srcSet={`${imgHero}`}/>
                <img src={imgHero} alt="This is an awesome image"/>
              </picture>

              <div className="guarantee-page">

                <div className="guarantee-intro">
                  <p>ShuttleID is the simple way to manage school and work transport. Used by over 70 transport providers and powering over 8 million passenger trips each year, we're celebrating <span style={{ color: '#fdd202', fontWeight: 'bold' }}>4 successful years</span> in operation.</p>
                  <p>Marking the anniversary, we are thrilled to introduce our new and improved guarantee – the longest of any comparable system supplier:</p>
                  <h1>100-day money back guarantee</h1>
                  <p>if we haven't delivered on our nine core promises.</p>
                  <p>With our extended guarantee, you can trust that we stand behind our product and are committed to your satisfaction.</p>
                </div>

                <div className="guarantee-promise-badge">
                  <img src={imgFiveStar} alt="" />
                  <img src={imgWeGuarantee} alt="" />
                  <img src={imgFiveStar} alt="" />
                </div>

                <div className="guarantee-promise-list">
                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>Saves time</h2>
                        <p>ShuttleID saves you time administrating your school, college or work shuttle service. Printing passes, manually taking payments over the phone, answering floods of calls and emails are a thing of the past.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"Across the business, ShuttleID saves us a full time member of staff."</p>
                      <p className="guarantee-quote-author"><span>Gareth Davies</span>Director, Lakeside Coaches</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>Stops fraud</h2>
                        <p>ShuttleID will help you put an end to your ticket fraud problems, even if you didn’t know you had them!</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"After introducing ShuttleID it identified we were carrying passengers who hadn’t paid a penny the previous term! Just in the first two weeks the system has already paid for itself."</p>
                      <p className="guarantee-quote-author"><span>James Thorpe</span>Managing Director, Harlequin Travel</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>Safer travel</h2>
                        <p>ShuttleID prevents overcrowding, lets drivers focus on driving, sends critical messages to customers instantly and provides an audit trail of everything that happens on your vehicles.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"It’s a system that doesn’t cost you a lot of money, but it ticks lots of safety boxes. And it also guarantees you your revenue."</p>
                      <p className="guarantee-quote-author"><span>Ceri Taylor</span>Managing Director, Applegates Coaches</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>Driver friendly</h2>
                        <p>ShuttleID can be used by any driver. The system is designed for every driver, young or old, full-time or part-time. Our first test pilot was an 80 year old non-smartphone user and has gone on to be.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"The drivers didn’t take to the other systems but they have taken to this!"</p>
                      <p className="guarantee-quote-author"><span>Alan Bullock</span>Director, Bullocks Coaches</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>User friendly</h2>
                        <p>ShuttleID has been battle-tested by tens of thousands of parents with varying levels of technical ability. In the event a customer has difficulty, we offer direct tech support taking the load off your customer-facing staff.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"It's worked even better than we envisaged. The students, we already knew they'd pick it up very quickly, they all like using it. Parents as well, they like it, they've got control over it, they've got it."</p>
                      <p className="guarantee-quote-author"><span>Dave Collier</span>Director, Cambridge Buses</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>1st class support</h2>
                        <p>When you need a helping hand, you will experience a personal service, which is both fast and expertly delivered. You always have the option to speak with a human and we'll know you on first name terms.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"Customers have commented how quick and easy it is to buy tickets. Chris at ShuttleID has been very helpful and has made the transition very easy for us."</p>
                      <p className="guarantee-quote-author"><span>Kevin Mortlock</span>Managing Director, Keane Travel</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>More control</h2>
                        <p>ShuttleID helps you take back control of your school and work services. Know exactly who’s boarding, when and where. Void tickets with the press of a button. All funds processed via our platform are always direct to you and without delay.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"We've got full observation and transparency, so we know the job's being done. The fraud has disappeared. I've also got the comfort of knowing that the revenue we do generate is in our bank quickly."</p>
                      <p className="guarantee-quote-author"><span>Ian Tetley</span>Managing Director, Tetley’s Coaches</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>No hidden fees</h2>
                        <p>We offer the best value ticket scanner available, starting at just £50. There is no set up fee on any of our ticketing packages. Our low-cost platform fee is the only charge we make to transport providers.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"Having just set up ShuttleID I can honestly say it’s a brilliant cost-effective system. Ideal for coach operators wanting a home-to-school ticketing system without the huge expense of hardware, but still gives better control of ticket issuing and usage."</p>
                      <p className="guarantee-quote-author"><span>Graeme Bethell</span>Partner, South Staffs Coaches</p>
                    </div>
                  </div>

                  <div className="guarantee-promise">
                    <div className="guarantee-text-parent">
                      <div className="guarantee-tick">
                        <img src={imgTick} alt="" />
                      </div>
                      <div className="guarantee-text">
                        <h2>Fast setup</h2>
                        <p>ShuttleID can be set up in less than 24 hours. For more complex configurations, our help portal and training materials will assist you, in addition to personal support from your account manager to get you set up as fast as possible and in the best way to achieve your goals.</p>
                      </div>
                    </div>
                    <div className="guarantee-quote-parent">
                      <p className="guarantee-quote">"I was a little daunted by the prospect, but setup was done quickly and went smoothly. It is such an easy system to use. Plus, the training materials we received from ShuttleID were great."</p>
                      <p className="guarantee-quote-author"><span>Joanne Harrison</span>Transport Co-Ordinator, York College</p>
                    </div>
                  </div>
                </div>

                <p>Terms and conditions apply</p>


              </div>

              <div className="article">
                <div className='article-inner'>
                  <h3 style={{ textAlign: 'center', color: '#9d874a', fontSize: '1.7em'}}>Find out how we can help</h3>
                  <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                    Submit your details below and we'll send you more information about our packages.
                  </p>
                  <form action="/info-requested/" name="guarantee-info" method="POST" className="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
                    <p style={{ display: 'none' }}>
                      <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <div className="contact-form__group">
                      <label>Name</label>
                      <input name="name" placeholder="Your name" required className="contact-form__input" />
                    </div>
                    <div className="contact-form__group">
                      <label>Company</label>
                      <input name="company" placeholder="Company" required className="contact-form__input" />
                    </div>
                    <div className="contact-form__group">
                      <label>Email</label>
                      <input name="email" placeholder="Email" type="email" required className="contact-form__input" />
                    </div>
                    <div className="contact-form__group">
                      <label>Phone</label>
                      <input name="phone" placeholder="Phone" required className="contact-form__input" />
                    </div>
                    <input type="hidden" name="form-name" value="guarantee-info" />
                    <p style={{ textAlign: 'center', paddingTop: '1em' }}>
                      <button className="btn" type="submit">Request info</button>
                    </p>
                  </form>
                </div>
              </div>
            </div>


          </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
